import * as React from "react"
import { Box, Button, makeStyles, Typography } from "@material-ui/core"
import { Link } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}))

const NotFoundPage = () => {
  const classes = useStyles()
  return (
    <section className={classes.root}>
      <Typography variant="h1">Страница не найдена</Typography>
      <Box mb={3} />
      <Link to="/" style={{ textDecoration: "none" }}>
        <Button variant="contained" color="primary" size="large">
          Вернуться домой
        </Button>
      </Link>
    </section>
  )
}

export default NotFoundPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
